import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './styles/reset.css';
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const params = new URLSearchParams(window.location.search);
const  pass = params.get('pass');

(function redirectIfNeeded() {
  if (pass !== '1sdf5SDglgd') {
    window.location.href = 'https://utsrus.com/company/career';
  }
})();

if (pass === '1sdf5SDglgd') {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
