import styles from './First90Days.module.css';


const First90Days = () => <div className={styles.first90Days}>
  <ul className={styles.first90Days__list}>
    <li>У вас прошел первый адаптационный период, и вы уже начали понимать бизнес-процессы связанные с вашей деятельностью. Возможно, уже приняли участие в своем первом субботнике ;)
 </li>
    <li>Вы не боитесь регулярно запрашивать обратную связь по Вашей работе, и встречаться один-на-один с руководителем..
</li>
    <li>А спустя три месяца совместно с руководителем вы подведете итоги, оцените выполнение задач, поставленных на ИС</li>
    <li>А после, вы поделитесь впечатлениями с отделом персонала на встрече:  
<ol className={styles.first90Days__secondlist}> 
  <li>Как прошел первый период адаптации, какие возникли сложности? </li>
  <li>Насколько были понятны задачи и обязанности на испытательный срок?</li>
  <li>Какие ожидания оправдались, а какие нет?</li>
</ol>
</li>
  </ul>

</div>
export default First90Days;