import styles from './FirstDay.module.css';

const FirstDay = () => <div  className={styles.firstDay}>


  <ul className={styles.firstDay__list}>
    <li  > 
      <span>Оформление трудоустройства. </span>  
      Вас встретит коллега, его контакты указаны в Welcome-письме, и проводит на оформление.
    </li> 
    <li> 
      <span>Знакомство. </span>  
      Мы покажем вам офис и со всеми познакомим.
    </li> 
    <li> 
      <span>Вам подготовят: </span>  
      пропуск, технику, рабочую почту, доступ в корп.портал, Help desk, 1С и др.
      Заказать всю необходимую канцелярию можно у офис-менеджера.
    </li> 
    <li> 
      В первую очередь, вас познакомят с рабочим ПО, корпоративным 
      порталом - Intranet. Там можно найти контакты сотрудников,  
      структуру компании, сервисы (Help desk, бронирование переговорных, 
      календарь тренингов и др.), материалы по обучению на различную тематику.
      Мы работаем в 1С, также всю отчетность ведем в CRM.  
      Вам сообщат адрес сетевого ресурса, где собраны презентации о 
      компании и продуктах, реквизиты, документы, логотип компании, 
      шаблоны писем и другая информация для работы.

    </li> 
    <li> 
      Будут организованы встречи с руководителем департамента,
       ведущими менеджерами и директором офиса, для знакомства, 
      ввода в должность и обсуждения задач на испытательный срок.
    </li> 
    <li> 
      В разделе <span>«Новым сотрудникам»</span>  корпоративного портала вы найдете важную информацию, 
      с которой необходимо познакомиться в первый рабочий день.
      
    </li> 
  </ul>

<div className={styles.firstDay__text}> До встречи в <span>ЕТС</span>!</div>
  </div>
export default FirstDay;