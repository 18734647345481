import styles from './Data.module.css';
import Title from '../title/Title';

const Data = () => 
<div className={styles.data}>
  <div id='data' className={styles.data__el}/>
  <div className={`container `}>
    <div className={styles.data__container}>
      <div className={styles.title__container}>
        <Title> Немного фактов </Title>
      </div>
      <div className={styles.data__text}>        
      <p >
        Мы крупнейший дистрибьютор химической продукции ведущих 
        мировых и российских производителей в 
        <span> России, Казахстане, Белоруссии, Узбекистане.</span>
      </p>
      <p >
        В 2019 году мы запустили проект <span>«Экспорт»</span>, 
        направленный на реализацию продукции российских предприятий 
        на рынки Азии, Индии, Турции и других стран. 
        В основе проекта – наши связи с партнерами и наша репутация.
      </p>
      <p >
        Мы предлагаем рынку готовые строительные решения, 
        авто-мото компоненты, товары для загородного домостроения,
        интерьерные и фасадные решения для коммерческих помещений, 
        ангарные ворота для промышленных объектов, 
        аккумуляторные батареи, моторные масла и другие системы.
      </p>
      <p >
        Сейчас у нас три производственные площадки:
      </p>
      <ol>
        <li><span>S&H TECHNOLOGY.</span> Производство специализированных ЛКМ (г. Санкт-Петербург)</li>
        <li><span>ЕТС - Синтез.</span> Производство метасиликата натрия и специальных блоков для шахт (г. Лепель, Беларусь).</li>
        <li><span>ЕТС - Полиуретановые системы. </span> (г. Логойск, Беларусь)  </li>
      </ol>
    </div>
  </div>
  </div>
</div>
export default Data;